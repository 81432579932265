.home-image {
  margin: 10px;
  width: 500px;
  height: 500px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .home-image {
    width: 350px;
    height: 350px;
    max-width: 500px;
    max-height: 500px;
  }

  .image-gallery-content .image-gallery-slide .image-gallery-image {
    max-height: 350px;
  }
}
