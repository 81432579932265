.service-grid {
  display: grid;
  grid-gap: 10px;
  margin: auto;
  grid-template-columns: 100%;
  max-width: 500px;
}

.service-grid-list {
  display: grid;
  grid-gap: 10px;
  margin: 10px;
  grid-template-columns: 1fr 1fr 1fr;
}

.service-package {
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.service-package .details {
  width: 100%;
  padding: 10px;
}

.details .item {
  margin-left:10px;
}

.service-package.list {
  height: 575px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .service-grid, .service-grid-list {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 10px;
    margin: 0px;
  }

  .service-grid-list {
    margin-top: 10px;
  }

  .service-package.list.centerpieces {
    height: 275px;
  }

  .service-grid .service-package {
    display: grid;
    grid-template-columns: 1fr;
    height: 100%;
  }
}