
.addLink {
  text-decoration: underline;
}

body.light {
  --bg: #fff;
  --header: #8a2be2;
  --textNormal: #333;
  --textTitle: #333;
  --textLink: #8a2be2;
  --textLinkLighter: #8a2be2;
  --hr: rgba(0, 0, 0, 0.2);
  --inlineCode-bg: rgba(255, 229, 100, 0.2);
  --inlineCode-text: #1a1a1a;
}
