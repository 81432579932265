.reviews-wrap {
  display: grid;
  grid-template-columns: 15% 85%;
  padding: 10px;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .reviews-wrap {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
  }
}

.card-layout { 
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 
              0px 3px 4px 0px rgb(0 0 0 / 14%), 
              0px 1px 8px 0px rgba(254, 206, 206, 0.12);
  min-height: 300px;
}
