
html {
  height: 100%;
}

body {
  font-family: Perpetua;
  font-size: 16px;
  line-height: 1.42857143;
  margin: 0;
}

form {
  margin: 10px;
  z-index: 1;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh; /* Avoid the IE 10-11 `min-height` bug. */
  width: 100%;
  overflow-y: hidden;
}

.content {
  flex: 1 0 auto;
  height: calc(100% - 55px);
}

.bodyContainer {
  width: 100%;
  height: calc(100% - 55px);
  overflow: auto;
}

.fixed-bottom {
  height: 55px;
}

footer {
  text-align: center;
  padding: 20px 0 20px 0;
  flex-shrink: 0;
  background-color: gainsboro;
}

header ul {
  padding-inline-start: 20px;
}

header li {
  display: inline;
  margin-right: 20px;
}

#detail img {
  max-height: 400;
}

input[type="number"] {
  width: 50px;
}

main {
  padding: 20px;
}

a, a:hover {
  text-decoration: none;
  background-color: transparent;
}

h1 {
  font-size: 36px;
  display: flex;
  flex-direction: row;
  justify-content: center;  
}

h1, h2 {
  margin-bottom: 0rem;
  padding-bottom: 0rem;
}

h3 {
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

h1,
h2,
h3,
h5 {
  font-weight: bold;
  color: rgba(0,0,0,.7);
}

.title {
  font-weight: bold;
  font-size: 18px
}

img {
  border: 0;
  vertical-align: middle;
  
}

.centerContent {
  justify-content: center;
  display: flex;
}

.leftContent {
  justify-content: left;
  display: flex;
}

.rightContent {
  justify-content: right;
  display: flex;
}

.btn-primary {
  color: #fff;
  background-color: #364147;
}

.heading-title {
  display: block;
  font-size: 3rem;
  font-weight: 800;
  margin-top: 1rem;
}

.heading-paragraph {
  display: block;
  font-size: 1.5rem;
  line-height: 1.5;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1rem;
  white-space: pre-line;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid transparent;
  white-space: nowrap;
  padding: 6px 12px;
  font-size: 18px;
  line-height: 1.42857143;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
}

p[role="alert"] {
  display: inline;
  margin-left: 10px;
  color: red;
}

div[role="alert"] {
  color: red;
}

.lds-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height:100%
}

.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #d3d3d3;
  border-color: #d3d3d3 transparent #d3d3d3 transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.react-toast-notifications__container {
  top: 70px !important;
}
