.react-datepicker-wrapper {
  width: 300px;
  padding-left: 20px;
}

input {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

input[type="number"], input[type="text"] {
  width: 100%;
}

input[type=checkbox], input[type=radio] {
  width: 25px;
}

label {
  margin-bottom: .1rem;
}

.react-datepicker-wrapper {
  padding-left: 0px;
  width: 100%;
}

.form-check {
  padding-left: 0rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    width: 100%;
}

.form-check-label {
  white-space: nowrap;
}

.form-checkbox {
  vertical-align: middle;
  justify-content: center;
  display: flex;
}